import React from 'react';
import ReactDOM from 'react-dom/client';
import cloneDeep from 'lodash/cloneDeep';
import './style.css';
import {Ingredient,IngredientType} from './ingredient.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';


let recipes = [];
let ingredients = [];
let ingredientsDef = [];
let reverseArrayRecette = {};
const urlpref="";
// const urlpref="http://localhost/tryReact/recettes/";

function compare( a, b ) {
  if ( a.titre < b.titre){
    return -1;
  }
  if ( a.titre > b.titre){
    return 1;
  }
  return 0;
}

function Footer(){
  return (
    <footer>
      <h4>Les recettes</h4>
      By Lapinou Korp. inc.
    </footer>);
}

let valTest;
function Listing(props){ 
  return ( 
    <aside id="listes">
      <div id="slbuttons">
        <button type="button" className="mainButton" onClick={()=> {props.save();}} >Save</button>
        <button type="button" className="mainButton" onClick={()=> {props.load();}} >Load</button>
      </div>
      <h1>Liste de courses</h1>
      <ul id="listeCourse">
      {Object.keys(props.shoppingList).map(r => {
        if(props.shoppingList[r] === 0) return "";
        return (
          <li key={r} onClick={e=>{e.target.classList.toggle("done");}}>
            {props.shoppingList[r]} {r.toString()} 
          </li>);})}
      </ul>
      <div id="addIng">
        <Autocomplete
          disablePortal
          id="nouvelItemCourse"
          options={ingredients}
          sx={{ width: 250}}
          renderInput={(params) => <TextField {...params} label="Nouvel item" />}
          onChange={(event, value) => valTest=value}
        />
        <button className="mainButton" type="button" onClick={()=>{props.addItemToList(valTest);}}>Ajouter à la liste</button>
      </div>
      <h1>Liste des recettes</h1>
      {Object.keys(props.recipeList).map(r => {
        if(props.recipeList[r] === 0) return "";
        return (
          <div key={r} className="recipeList">
            <button type="button" className="btnList"  
              onClick={()=> {
                props.setJumpTo(reverseArrayRecette[r].titre)
                props.setPageContent(reverseArrayRecette[r].type)
              }}
            >
              {r} ({props.recipeList[r]})
            </button>
            <button type='button' className="buttonRemove" onClick={()=>{props.removeRecipeFromLists(reverseArrayRecette[r])}}>x</button>
          </div>
        );})}
    </aside>);
}

function Header(props){
  return(
    <header>
      <h1>Les recettes</h1>
      <nav> 
        <button type="button" className={"mainButton"+ (props.printedPage==='Salee'?' active':'')} onClick={()=>{props.setR("Salee")}}>Salées</button>
        <button type="button" className={"mainButton"+ (props.printedPage==='Sucree'?' active':'')} onClick={()=>{props.setR("Sucree")}}>Sucrées</button>
        <button type="button" className={"mainButton"+ (props.printedPage==='Festif'?' active':'')} onClick={()=>{props.setR("Festif")}}>Festif</button>
        <button type="button" className={"mainButton"+ (props.printedPage==='ATester'?' active':'')} onClick={()=>{props.setR("ATester")}}>À Tester</button>
        <button type="button" className={"mainButton"+ (props.printedPage==='nouvIng'?' active':'')} onClick={()=>{props.setR("nouvIng")}}>Nouvel ingredient</button>
      </nav>
    </header> );
}

function Recette(props){
  return (
    <article>
      <details>
        <summary ref={props.jumpRef}>{props.r.titre} ({props.r.nbParts})</summary>
        <h2>Ingredients</h2>
        <ol>
          {props.r.ingredients.map((ing, ind)=>{return <li key={ind}>{ing.toString()}</li>;})}
        </ol> 
        <h2>Consignes</h2>
        <ol>
          {props.r.recette.map((cons, ind)=>{return <li key={ind}>{cons}</li>;})}
        </ol>
      </details>
      <button type='button' className="buttonAdd" onClick={()=>{props.addRecipeToLists(props.r)}}>+</button>
    </article>); 
}

class Main extends React.Component{
  constructor(props) {   
    super(props);
    this.jumpRef=React.createRef()  
  }
  componentDidUpdate(){
    if(this.jumpRef.current !== null && this.props.jumpTo !== ""){
      this.jumpRef.current.scrollIntoView({behavior: 'smooth'});
      this.props.setJumpTo("");
    }
  }
  render(){
    if(this.props.printedPage === 'nouvIng'){
      return(
        <main>
          <form onSubmit={e=>{
            e.preventDefault();
            const data = new FormData(e.target);
            fetch(urlpref+"php/addIngredient.php",
              { body: data,
                method: "post"})
          }}>
            <p>
              <label htmlFor="ingName">{"Nom de l'ingrédient "}: </label>
              <input id="ingName" name="nom"/>
            </p>
            <p>
              <label htmlFor="ingUnite">{"Unite de l'ingrédient "}: </label>
              <input id="ingUnite" name="unite"/>
            </p>
            <input type="submit" className="mainButton"/>
          </form>
        </main>);
      
    }else{
      return(
        <main>
          {recipes.map((r)=>{
            if(this.props.printedPage !== r.type)  return "";
            return (
              < Recette 
                key={r.titre} 
                r={r} 
                addRecipeToLists={this.props.addRecipeToLists} 
                jumpRef={((this.props.jumpTo===r.titre)?this.jumpRef:null)}
              />)}
            )}
        </main>);
    }
  }
}

class Page extends React.Component{  
  constructor(props) {   
    super(props);   
    this.state = {   
      printedPage: "Salee",
      recipeList: {},  
      shoppingList: {} ,
      jumpTo:"",
    };
  }
  
  setPageContent(recipeType){
     this.setState({         
      printedPage: recipeType,    
    });  
  }
  
  setJumpTo(jumpTo){
    this.setState({         
      jumpTo: jumpTo,    
    });  
  }
  
  save(){
    let toSave = JSON.stringify([this.state.shoppingList, this.state.recipeList]);
    fetch(urlpref+"php/save.php", {
      method: 'POST',
      headers: {"Accept": "application/json", 'Content-Type': 'application/x-www-form-urlencoded',  },
      body: "tosave="+toSave
    });
  }
  
  load(){
    fetch(urlpref+"php/load.php",
      { headers: {"Accept": "application/json"}})
    .then(data => {return data.json();})
    .then(data => {
      this.setState({         
        shoppingList: data[0],  
        recipeList: data[1],    
      });  
    });
  }
  
  
  addRecipeToLists(r){
    const recipeList = cloneDeep(this.state.recipeList); 
    if(! (r.titre in recipeList)) recipeList[r.titre] = 0;
    recipeList[r.titre]++; 
    const shoppingList = cloneDeep(this.state.shoppingList); 
    r.ingredients.forEach(ing=>{
      if(! (ing.type in shoppingList)) shoppingList[ing.type] = 0;
      shoppingList[ing.type] += ing.quantite}
    );
    this.setState({  
      recipeList:recipeList,
      shoppingList:shoppingList,
    });
  }
  
  removeRecipeFromLists(r){
    const recipeList = cloneDeep(this.state.recipeList); 
    if(r.titre in recipeList && recipeList[r.titre] >0){
      recipeList[r.titre]--; 
      const shoppingList = cloneDeep(this.state.shoppingList); 
      r.ingredients.forEach(ing=>{shoppingList[ing.type] -= ing.quantite});
      this.setState({  
        recipeList:recipeList,
        shoppingList:shoppingList,
      });
    }
  }  
  
  addItemToList(item){
    let type =ingredientsDef[valTest];
    const shoppingList = cloneDeep(this.state.shoppingList); 
    if(! (type in shoppingList)) shoppingList[type] = 0;
      shoppingList[type] +=1;
    this.setState({  
      shoppingList:shoppingList,
    });
  }
  
  render() {
    return (
      <div id="wrapper">
        <Listing
          recipeList={this.state.recipeList}
          shoppingList={this.state.shoppingList}
          setJumpTo={ j => this.setJumpTo(j) }
          setPageContent={ e => this.setPageContent(e)}
          removeRecipeFromLists={ r => this.removeRecipeFromLists(r)}
          save={()=> {this.save()}}
          load={()=> {this.load()}}
          addItemToList={()=> {this.addItemToList()}}
        />
        <div id="wrap">
          <Header 
            setR={ e => this.setPageContent(e)}
            printedPage={this.state.printedPage}
          />
          <Main 
            printedPage={this.state.printedPage}
            setJumpTo={ j => this.setJumpTo(j) }
            addRecipeToLists={ r => this.addRecipeToLists(r)}
            jumpTo={this.state.jumpTo}
          />
          <Footer/>
        </div>
      </div>
    );
  }
}

// ========================================
fetch(urlpref+"php/loadRecettes.php",
      { headers: {"Accept": "application/json"}})
  .then(data => {return data.json();})
  .then(data => { 
    recipes = data.map(r => {
      return {
        titre: r.titre,
        type: r.type,
        nbParts: r.nbParts,
        ingredients: r.ingredients.map(ing => new Ingredient(ing.quantite, ing.unite, ing.nom)),
        recette: r.recette
      }
    });
    fetch(urlpref+"php/loadIngredients.php",
      { headers: {"Accept": "application/json"}})
    .then(data => {return data.json();})
    .then(data => { 
      ingredients = data.map(ing => {ingredientsDef[ing.nom] = new IngredientType(ing.unite, ing.nom); return ing.nom});
      recipes.sort(compare);
      recipes.forEach(r => {reverseArrayRecette[r.titre] = r;})
      console.log(ingredientsDef);
      const root = ReactDOM.createRoot(document.getElementById("root"));
      root.render(<Page />);
    });
  });


