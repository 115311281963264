
export class IngredientType{
  constructor(unite, nom) {
    this.nom = nom;
    this.unite = unite;
  }
  toString(){ 
    return ''+this.unite+' '+this.nom;
  }
}

export class Ingredient{
  constructor(quantite, unite, nom) { 
    this.quantite = quantite;
    this.type = new IngredientType(unite, nom)
  }
  toString(){ 
    return ''+this.quantite+' '+this.type.unite+' '+this.type.nom;
  }
}
